import React from "react"
import styled from "styled-components"

const Title = props => (
  <TitleWrapper id={props.id} props={props}>{props.children}<span class="color-primary">.</span></TitleWrapper>
)

const TitleWrapper = styled.div`
    padding: 5px 15px 10px 0px;
    font-weight: bolder;
    font-size: 38px;
    color: white;
    
    @media (max-width: 768px) {
        font-size: 30px;
    }
`

export default Title
